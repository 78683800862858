import React from "react";
import { Link } from "react-router-dom";
import { userService } from 'services/user.service';
import { authenticationService } from 'services/authentication.service';
import ReCAPTCHA from "react-google-recaptcha";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Modal,
  Row,
  Alert,
  Col
} from "reactstrap";

// core components
import AppNavbar from "components/Navbars/AppNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import {auditService} from "../services/audit.service";

class Register extends React.Component {
  
 
  constructor() {
    super();
    this.state = {
      username: '',
      email: '',
      phone:'',
      password: '',
      fullName:'',
      confirmPassword: '',
      formSuccess: false,
      successModal: false,
      failureModal: false,
      errorMessage: '',
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this._reCaptchaRef = React.createRef();
  }
  traceCreateAccount(userName){
    auditService.trace("Create account", "Register page", new Date(),userName,null)
        .then(r  => {})
  }
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
   if(state === "close"){
     window.location.replace("/login");
    }
  };
  toggleFailModal =  (state,msg) => {
    this.setState({
      [state]: !this.state[state],
      errorMessage : msg
    });
  };
  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }
  handleSubmit(e) {
    this._reCaptchaRef.current.execute();
    e.preventDefault();
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    // redirect to home if already logged in
    if (authenticationService.currentUserValue) { 
      this.props.history.push('/');
    }
  }
  isFormValid = () => {
    const {username, email, password, confirmPassword} = this.state
    if(password && confirmPassword && password !== confirmPassword){
        document.getElementById("password-input").classList.add("has-error");
        document.getElementById("cpassword-input").classList.add("has-error");
    }else{
        let element1 =  document.getElementById("password-input");
        if(element1) element1.classList.remove("has-error");
        let element2 =  document.getElementById("cpassword-input");
        if(element2) element2.classList.remove("has-error");
    }
    return username && email && password && confirmPassword && password === confirmPassword
  }
  handleChange = value => {
    console.log("onChange prop - Captcha value:", value);
    const {username, email,phone, password,fullName} = this.state
    userService.saveUser(username,email,phone,password,fullName)
            .then(
                user => {
                  this.traceCreateAccount(username);
                  this.toggleModal("successModal");
                },
                (error) => {
                  let errorType = new String(error);
                  if(error !== undefined && error !== null && !errorType.includes("TypeError")){
                    console.log(JSON.stringify(JSON.parse(error)))
                    let message = JSON.parse(error);
                    this.toggleFailModal("failureModal",message.errorMessage);
                  }else{
                    this.toggleFailModal("failureModal","Internal error please try again later.");
                  }

                }
             );
  };
  onSaveUser = () => {
    this._reCaptchaRef.current.execute();
  }
  render() {

    return (
      <>
        <AppNavbar />
        {/* on success model */}
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.successModal}
          toggle={() => this.toggleModal("successModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
             Welcome
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("close")}
            >
              <span aria-hidden={true}>X</span>
            </button>
          </div>
          <div className="modal-body">
            <Alert className="alert-default">
              <span className="alert-inner--text">
                <strong></strong>
                <p className="modal-message"> User Account created with success</p>
              </span>
             </Alert>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              className="btn-close-modal"
              onClick={() => this.toggleModal("close")}
            >
              Close
            </Button>
          </div>
        </Modal>
        {/* on failure model */}
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.failureModal}
          toggle={() => this.toggleFailModal("failureModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title modal-title-error" id="exampleModalLabel">
             Sorry!
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleFailModal("failureModal")}
            >
              <span aria-hidden={true}>X</span>
            </button>
          </div>
          <div className="modal-body">
           {/** <p className="modal-message-error"></p> */}
            <Alert color="warning">
              <span className="alert-inner--text">
                <strong></strong>
                <p className="modal-message-error">{this.state.errorMessage}</p>
              </span>
           </Alert>
              
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              className="btn-close-modal"
              onClick={() => this.toggleFailModal("failureModal")}
            >
              Close
            </Button>
          </div>
        </Modal>
        <main ref="main" className="register-page">
          <section className="section section-shaped section-lg">
            <Container className="pt-lg-7">
              <Row className="justify-content-center">
              <Col lg="7">
{/*               <div>
                            <img
                            alt="..."
                            className="register-portal-logo"
                            src={require("assets/img/portal_logo.png")}
                          />
                          <h3 className="register-portal-logo-text">PowerCARD</h3>
                          <h2 className="register-portal-logo-text-bold">CONNECT'API</h2>
                      </div>  */}
                <h3 className="create-acc-title">
                <i className="fa fa-cloud cust-sandbox-icon" />
                  Sandbox Accounts</h3>
                 <p className="create-acc-sub-title">A Sandbox Account enables integration partners to explore the functionality available on the Connect’API platform and test both the technical and business value of their integration.</p>
              </Col>
                <Col lg="5">
                  <Card className="bg-secondary shadow border-0 create-acc-card">
                    <CardBody className="px-lg-5 py-lg-5">    
                      <div className="text-center text-muted mb-4 text-register">
                        <small>Create Sandbox Account </small>
                      </div>
                      <Form role="form" onSubmit={this.handleSubmit}>
                      <ReCAPTCHA
                          style={{ display: "inline-block" }}
                          theme="dark"
                          size="invisible"
                          ref={this._reCaptchaRef}
                          sitekey="6LdVRYEfAAAAAA-m-lctYvYrmVWnZ3yT3TMKJsQH"
                          onChange={this.handleChange}
                        />,
                        <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-hat-3" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                             value={this.state.username}
                             onChange={(event) => this.handleUserInput(event)}
                             id="username" name="username" placeholder="Username" type="text" required
                              />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-circle-08" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                             value={this.state.fullName}
                             onChange={(event) => this.handleUserInput(event)}
                             id="fullName" name="fullName" placeholder="Fullname" type="text" required
                              />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                             onChange={(event) => this.handleUserInput(event)}
                             value={this.state.email}
                             name="email"
                             id="email" placeholder="Email" type="email" required />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-mobile-button" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                             onChange={(event) => this.handleUserInput(event)}
                             value={this.state.phone}
                             name="phone"
                             id="phone" placeholder="Phone" pattern="[0-9]*" type="text"  onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }} required />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup id="password-input" className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Password"
                              id= "password"
                              name="password"
                              onChange={(event) => this.handleUserInput(event)}
                              type="password"
                              value={this.state.password}                  
                              autoComplete="off"
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup id="cpassword-input" className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Confirme Password"
                              onChange={(event) => this.handleUserInput(event)}
                              value={this.state.confirmPassword}
                              type="password"
                              name="confirmPassword"
                              id= "confpassword"
                              type="password"
                              autoComplete="off"
                            />
                          </InputGroup>
                        </FormGroup>
                        <Row className="my-4">
                          <Col xs="12">
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <input
                                className="custom-control-input"
                                id="customCheckRegister"
                                type="checkbox"
                              />
                              {/** 
                              <label
                                className="custom-control-label"
                                htmlFor="customCheckRegister"
                              >
                                <span>
                                  I agree with the{" "}
                                  <a
                                    href="#pablo"
                                    onClick={e => e.preventDefault()}
                                  >
                                    Privacy Policy
                                  </a>
                                </span>
                              </label>*/}
                              <label>
                                <span >
                                  <Link 
                                  href=""
                                  className="register-signin"
                                    to="/login" tag={Link}
                                  >
                                    Sign in with your existing account
                                  </Link >
                                </span>
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <div className="text-center">
                          <Button
                            className="mt-4 btn-save-acc"
                            color="primary"
                            type="button"
                            disabled={!this.isFormValid()}
                            onClick={this.onSaveUser}
                          >
                            Create account
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
                
              </Row>

            </Container>
            <div className="separator separator-bottom separator-skew version-sep"></div>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Register;
